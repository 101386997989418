@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --header-width: 132px;
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  src: url("../fonts/Roboto.ttf") format("truetype");
}

body {
  background-color: #111827;
}
